import { TranslationDto } from "@/dtos/TranslationDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { TranslationModel } from "@/models/TranslationModel";
import { TranslationListViewModel, TranslationViewModel } from "@/viewModels/ViewModels";

export class TranslationApiController extends BaseApiController<TranslationModel, TranslationDto, TranslationViewModel, TranslationListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.TRANSLATIONS);
  }
}
