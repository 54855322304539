import { ModelDto } from "./base/ModelDto";
import { LogEntryModel } from "@/models/LogEntryModel";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";

class LogEntryDto extends ModelDto<LogEntryModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.LogEntryModel(x),
    },
  ];
}

class LogEntryListDto extends ListDto<LogEntryDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.LogEntryDto(y)),
    },
  ];
}

export { LogEntryDto, LogEntryListDto };
