import * as Yup from "yup";
import { BaseDto } from "../base/BaseDto";

export abstract class BaseFormData<T extends Yup.AnyObject> {
  public shouldValidate: boolean = false;
  public formSchema!: Yup.ObjectSchema<T>;

  // is false if entity is not found in store and is not newly created.
  protected isInitializedProperly: boolean = false;
  // is true if entity id is uninitialized (-1).
  protected isInitializedWithNewEntity: boolean = false;

  private baseDto: BaseDto;

  constructor(dto: BaseDto) {
    this.baseDto = dto;
  }

  public isReady() {
    return this.isExistingOrNew();
  }

  public isExistingOrNew() {
    return this.isInitializedProperly;
  }

  public isExisting() {
    return this.isInitializedProperly && !this.isNew();
  }

  public isNew() {
    return this.isInitializedWithNewEntity;
  }
  
  public isValidForm() {
    return !this.shouldValidate || this.baseDto.validationErrorFields.length === 0;
  }
}
