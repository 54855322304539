<template>
  <div class="env" style="display: none;">
    <div>
      <span>Environment:</span>
      <span>{{ env }}</span>
    </div>
    <div>
      <span>User:</span>
      <span>{{ user }}</span>
    </div>
    <div>
      <span>Role:</span>
      <span>{{ userRole }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { useUserStatusStore } from "@/stores/userStatusStore";
import { computed, defineComponent } from "vue";

/**
 * Component used to render debug information like Env or logged in user info.
 */
export default defineComponent({
  name: "DebugDisplay",
  setup() {
    const userStore = useUserStatusStore();

    const env = computed(() => process.env.NODE_ENV);
    const user = computed(() => `${userStore.userDto?.model?.username} (Id: ${userStore.userDto?.model?.id})`);
    const userRole = computed(() => `${userStore.userDto?.model?.role?.name} (Id: ${userStore.userDto?.model?.role?.id})`);

    return {
      env,
      user,
      userRole,
    };
  },
});
</script>

<style scoped lang="scss">
.env {
  position: fixed;
  bottom: 3px;
  left: 3px;
  padding: 2px 5px;
  background-color: white;
  border: 1px solid red;
  color: red;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  opacity: 0.8;
  
  div {
    display: flex;
    flex-direction: row;
    column-gap: 10px;

    span:first-child {
      min-width: 100px;
    }
  }
}
</style>
