import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { LogEntryMessageType } from "@/enums/LogEntryMessageType";
import { LogEntryType } from "@/enums/LogEntryType";
import { ILogEntryModel } from "./interfaces/ILogEntryModel";

export class LogEntryModel extends BaseModel implements ILogEntryModel {
  public logEntryType: LogEntryType;
  public logEntryMessageType: LogEntryMessageType;
  public message: string;
  public requestUserAgent: string;
  public requestOrigin: string;
  public requestIpAddressV4: string;
  public requestDataRaw: string;

  constructor() {
    super();

    this.logEntryType = LogEntryType.NONE;
    this.logEntryMessageType = LogEntryMessageType.NONE;
    this.message = "";
    this.requestUserAgent = "";
    this.requestOrigin = "";
    this.requestIpAddressV4 = "";
    this.requestDataRaw = "";
  }

  public static readonly mappings: TypeMapping[] = [];
}
