import { BaseDto } from "./BaseDto";

export class ModelDto<T> implements BaseDto {
  public model: T;

  public isDirty: boolean;
  public isLoading: boolean;
  public hasError: boolean;
  public validationErrorFields: string[];

  constructor(source: T) {
    this.model = source;
    this.isLoading = false;
    this.isDirty = false;
    this.hasError = false;
    this.validationErrorFields = [];
  }
}
