import { LogEntryDto } from "@/dtos/LogEntryDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { LogEntryListViewModel, LogEntryViewModel } from "@/viewModels/ViewModels";
import { LogEntryModel } from "@/models/LogEntryModel";

export class LogEntryApiController extends BaseApiController<LogEntryModel, LogEntryDto, LogEntryViewModel, LogEntryListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.LOG_ENTRIES);
  }
}
