import { TypeMapping } from "../dtos/shared/TypeMapping";
import { genericMappings } from "./TypeHelper";

/**
 * Helps with basic object operations in JS.
 */
export const ObjectHelper = {
  /* -------------------------------------------
  Copies all properties (type-safe when defined in mappings) that exist in both objects from "from" to "to".
  Returns the "to" object.
  ------------------------------------------- */
  copyExistingPropsFromTo(from: any, to: any, mappings: TypeMapping[] = []): any {
    const _mappings = [...mappings, ...genericMappings];

    if (from && to) {
      Object.keys(from)
        .filter((key) => key in to)
        .forEach((key) => {
          const mapping = _mappings.find((m) => m.key === key);
          if (mapping) {
            to[key] = mapping.cast(from[key]);
          } else {
            to[key] = from[key];
          }
          // console.log(`${key}: ${typeof from[key]} -> ${typeof to[key]}`);
        });
    }
    return to;
  },
};
