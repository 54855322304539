export class BaseModel {
  id: number;
  createdAt: Date;
  updatedAt?: Date | null;

  constructor() {
    const defaultDate = new Date(1990, 0);

    this.id = NaN;
    this.createdAt = defaultDate;
    this.updatedAt = null;
  }
}
