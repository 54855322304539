import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { UserDto } from "../UserDtos";
import { LoginData } from "./LoginData";
import { UserModel } from "@/models/UserModel";
import { toRaw } from "vue";

export class LoginFormData extends BaseFormData<LoginData> implements LoginData {
  public username: string;
  public password: string;

  public originalData: UserModel;

  constructor(dto: UserDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.username = dto.model.username;
    this.password = dto.model.password;

    this.originalData = structuredClone(toRaw(dto.model));
    
    this.formSchema = Yup.object({
      username: Yup.string().email().required(),
      password: Yup.string().required(),
    });
  }
}
