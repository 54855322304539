import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { RoleType } from "@/enums/RoleType";

export class RoleModel extends BaseModel {
  roleType: RoleType;
  name: string;

  constructor() {
    super();

    this.roleType = RoleType.NONE;
    this.name = "";
  }

  public static readonly mappings: TypeMapping[] = [];
}
