import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { toRaw } from "vue";
import { ILogEntryModel } from "@/models/interfaces/ILogEntryModel";
import { LogEntryModel } from "@/models/LogEntryModel";
import { LogEntryMessageType } from "@/enums/LogEntryMessageType";
import { LogEntryType } from "@/enums/LogEntryType";
import { LogEntryDto } from "../LogEntryDtos";

export class LogEntryFormData extends BaseFormData<ILogEntryModel> implements ILogEntryModel {
  public logEntryType: LogEntryType;
  public logEntryMessageType: LogEntryMessageType;
  public message: string;
  public requestUserAgent: string;
  public requestOrigin: string;
  public requestIpAddressV4: string;
  public requestDataRaw: string;

  public originalData: LogEntryModel;

  constructor(dto: LogEntryDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.logEntryType = dto.model.logEntryType;
    this.logEntryMessageType = dto.model.logEntryMessageType;
    this.message = dto.model.message;
    this.requestUserAgent = dto.model.requestUserAgent;
    this.requestIpAddressV4 = dto.model.requestIpAddressV4;
    this.requestOrigin = dto.model.requestOrigin;
    this.requestDataRaw = dto.model.requestDataRaw;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      logEntryType: Yup.mixed<LogEntryType>().required(),
      logEntryMessageType: Yup.mixed<LogEntryMessageType>().required(),
      message: Yup.string().required(),
      requestUserAgent: Yup.string().required(),
      requestIpAddressV4: Yup.string().required(),
      requestOrigin: Yup.string().required(),
      requestDataRaw: Yup.string().required(),
    });
  }
}
