import { ModelDto } from "./base/ModelDto";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";
import { TranslationModel } from "@/models/TranslationModel";

class TranslationDto extends ModelDto<TranslationModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.TranslationModel(x),
    },
  ];
}
class TranslationListDto extends ListDto<TranslationDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.TranslationDto(y)),
    },
  ];
}

export { TranslationDto, TranslationListDto };
