<template>
    <div class="title-panel">
    <h2>Übersetzung bearbeiten</h2>
  </div>

  <TranslationsCreateEdit :id="id" :dto="dto"></TranslationsCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { TranslationDto } from "@/dtos/TranslationDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import TranslationsCreateEdit from "@/components/controlpanel/translations/TranslationsCreateEdit.vue";
import { TranslationModel } from "@/models/TranslationModel";
import { useTranslationStore } from "@/stores/translationStore";

/**
 * View that renders the the "edit" form for translations.
 */
export default defineComponent({
  name: "TranslationsEdit",
  setup() {
    const store = useTranslationStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/translations");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new TranslationDto(new TranslationModel()));

    // get entity from store.
    onMounted(() => {
      store.getTranslation(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { TranslationsCreateEdit },
});
</script>

<style scoped lang="scss"></style>
